<template>
  <div class="subscribed">
    <template v-if="is_subscribe">
      <div class="header-subscribe text-center">
        <div class="text-h3 fw-600 py-5">You've been submitted</div>
        <!-- <div>Thanks for confirming your email address</div> -->
        <div class="text-h6">
          Thanks your selection we want to save your data or do will save your
          data as per you seletion.
        </div>
        <div @click="toggleSubscribe" class="blue--text py-8 cursor-pointer">
          Review your email preferences
        </div>
      </div>
    </template>
    <template v-else>
      <div class="update-preference">
        <div class="text-h3 fw-600 py-5 text-center">Update preferences</div>
        <div class="mx-auto" style="width: 35%">
          <v-form
            ref="verificationForm"
            v-model.trim="formValid"
            lazy-validation
          >
            <v-row>
              <v-col md="12">
                <label for="email" class="font-weight-500">Email</label>
                <v-text-field
                  v-model.trim="email"
                  dense
                  filled
                  label="Email"
                  solo
                  flat
                  :disabled="formLoading || pageLoading"
                  :loading="formLoading"
                  color="cyan"
                ></v-text-field>
              </v-col>
              <v-col md="12">
                <label for="first_name" class="font-weight-500"
                  >First Name</label
                >
                <v-text-field
                  v-model.trim="first_name"
                  dense
                  filled
                  label="First Name"
                  solo
                  flat
                  :disabled="formLoading || pageLoading"
                  :loading="formLoading"
                  color="cyan"
                ></v-text-field>
              </v-col>
              <v-col md="12">
                <label for="last_name" class="font-weight-500">Last Name</label>
                <v-text-field
                  v-model.trim="last_name"
                  dense
                  filled
                  label="Last Name"
                  solo
                  flat
                  :disabled="formLoading || pageLoading"
                  :loading="formLoading"
                  color="cyan"
                ></v-text-field>
              </v-col>
              <v-col md="12">
                <v-btn
                  depressed
                  class="custom-grey-border custom-bold-button white--text w-100"
                  color="cyan"
                  >Update preferences</v-btn
                >
                <div
                  @click="toggleSubscribe"
                  class="blue--text py-8 cursor-pointer text-center"
                >
                  Unsubscribe
                </div>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </div></template
    >
  </div>
</template>
<script>
export default {
  name: "customer-subscribe",
  data: () => {
    return {
      formValid: true,
      formLoading: false,
      pageLoading: false,
      is_subscribe: true,
      email: "",
      first_name: "",
      last_name: "",
    };
  },
  methods: {
    toggleSubscribe() {
      this.is_subscribe = !this.is_subscribe;
      console.log("is_subscribe:", this.is_subscribe);
    },
  },
};
</script>
