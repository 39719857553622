<template>
  <v-app>
    <v-container class="py-0" style="width: 1000px">
      <v-row>
        <v-col md="12">
          <div class="header-details">
            <table width="100%">
              <tr>
                <td>
                  <img
                    style="width: 200px; height: auto"
                    src="https://bthrust-fsm.s3.ap-southeast-1.amazonaws.com/SL012021VF/files/image/png/MwRhbCQB4IY4bY5pEnlcZiY6mjRT6BEtUz5ss4HM.png"
                    alt="logo"
                  />
                  <div class="pt-3">
                    <div class="text-h5 fw-600">John Doe</div>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </v-col>
        <template v-if="!isSubmitted">
          <v-col md="12">
            <div class="bg-white h-100 border pa-3 rounded">
              <v-col md="12" class="py-0">
                <table width="100%">
                  <tr>
                    <td>
                      <span class="text-h5 font-weight-700"
                        >Data Collection Consent</span
                      >
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span
                        class="font-weight-700 text-h6"
                        style="border-bottom: 1px solid"
                        >Terms & Conditions</span
                      >
                    </td>
                  </tr>
                  <tr>
                    <td class="py-1">
                      <div><b>1. Introduction</b></div>
                      <p>
                        Thank you for visiting
                        <strong>Business Thrust Pte Ltd</strong> Consent Page.
                        To provide you with personalized services and improve
                        our offerings, we may collect certain data in line with
                        our privacy practices. We value your trust and ensure
                        that all data will be processed securely and in
                        accordance with applicable regulations.
                      </p>
                      <div><b>2. What Data We Collect</b></div>
                      <ul>
                        <li>
                          Basic information such as name, contact details, and
                          preferences.
                        </li>
                        <li>
                          Usage data to understand how you interact with our
                          services
                        </li>
                      </ul>
                      <div><b>3. How We Use Your Data</b></div>
                      <p>We use this information to:</p>
                      <ul>
                        <li>Tailor our services to better meet your needs</li>
                        <li>Enhance customer service and support</li>
                        <li>
                          Conduct market research and analysis to improve our
                          offerings
                        </li>
                      </ul>
                      <div><b>4. Your Choices</b></div>
                      <p>
                        We believe in giving you control over your data. You can
                        choose to accept or reject our data collection practices
                        below.
                      </p>
                      <v-radio-group v-model="isConfirm" column>
                        <v-radio
                          v-on:click="
                            action_type = 'accept';
                            action_dialog = true;
                          "
                          color="green"
                          value="agree"
                        >
                          <template v-slot:label>
                            <div>
                              <b class="black--text">
                                Accept Data Collection Terms</b
                              >
                            </div>
                          </template></v-radio
                        >
                        <v-radio
                          v-on:click="
                            action_type = 'reject';
                            action_dialog = true;
                          "
                          color="red"
                          value="disAgree"
                        >
                          <template v-slot:label>
                            <div>
                              <b class="black--text"
                                >Reject Data Collection Termse</b
                              >
                            </div>
                          </template></v-radio
                        >
                      </v-radio-group>
                      <p>
                        For more details, please read our full
                        <span class="blue--text cursor-pointer"
                          >Privacy Policy.</span
                        >
                      </p>
                      <p>Thank you for helping us serve you better.</p>
                      <p>
                        <span class="blue--text cursor-pointer">Resend</span>
                      </p>
                    </td>
                  </tr>
                </table>
                <!-- <hr />
                <div class="text-h6 fw-600">Consent for Data Collection</div> -->
                <table width="100%" v-if="false">
                  <tr>
                    <td>
                      <div>
                        To enhance your experience with our services, we kindly
                        ask for your consent to collect and use some of your
                        personal data. By agreeing, you allow us to:
                      </div>
                      <ul>
                        <li>
                          <b>Personalize</b> your experience and provide
                          relevant updates.
                        </li>
                        <li>
                          <b>Improve</b> our services and tailor content to your
                          preferences.
                        </li>
                        <li>
                          <b>Analyze</b> interactions to enhance product quality
                          and functionality.
                        </li>
                      </ul>
                      <div>
                        We value your privacy and are committed to safeguarding
                        your information. You can withdraw your consent at any
                        time, and we will only use your data for the purposes
                        outlined.
                      </div>
                      <div>Please indicate your preference below:</div>
                      <v-radio-group v-model="isConfirm" column>
                        <v-radio
                          v-on:click="
                            action_type = 'accept';
                            action_dialog = true;
                          "
                          color="green"
                          value="agree"
                        >
                          <template v-slot:label>
                            <div>
                              <b class="black--text"> Yes I Agree</b> to the
                              collection and use of my data as described above.
                            </div>
                          </template></v-radio
                        >
                        <v-radio
                          v-on:click="
                            action_type = 'reject';
                            action_dialog = true;
                          "
                          color="red"
                          value="disAgree"
                        >
                          <template v-slot:label>
                            <div>
                              <b class="black--text">No I Do Not Agree</b>
                            </div>
                          </template></v-radio
                        >
                      </v-radio-group>
                      <!-- <div>
                        <v-checkbox
                          v-model="agree"
                          color="green"
                          v-on:click="
                            action_type = 'reject';
                            action_dialog = true;
                          "
                        >
                          <template v-slot:label>
                            <div>
                              <b class="black--text"> Yes I Agree</b> to the
                              collection and use of my data as described above.
                            </div>
                          </template>
                        </v-checkbox>
                      </div>
                      <div>
                        <v-checkbox v-model="disAgree" color="red">
                          <template v-slot:label>
                            <div>
                              <b class="black--text">No I Do Not Agree</b>
                            </div>
                          </template>
                        </v-checkbox>
                      </div> -->
                    </td>
                  </tr>
                </table>
              </v-col>
            </div>
          </v-col>
        </template>
        <template v-else>
          <div class="bg-white h-100 border pa-3 rounded w-100">
            <v-col md="12">
              <SubscribeSubmit></SubscribeSubmit>
            </v-col>
          </div>
        </template>
      </v-row>
    </v-container>

    <Dialog :common-dialog="action_dialog">
      <template v-slot:title> Confirmation </template>
      <template v-slot:body>
        <v-container>
          <p class="font-size-19 font-weight-600 mx-2 my-0">
            Are you sure, you want to
            <span
              class="font-weight-700"
              :class="{
                'red--text text--lighten-1': action_type == 'reject',
                'green--text text--lighten-1': action_type == 'accept',
              }"
            >
              <!-- {{ action_type }} -->
              <template v-if="action_type == 'accept'"> Accept</template>
              <template v-else> Reject</template>
            </span>
            this ?
          </p>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          depressed
          class="mx-2 custom-grey-border custom-bold-button white--text"
          color="cyan"
          v-on:click="action_submit()"
          :disabled="formLoading"
          :loading="formLoading"
        >
          Submit
        </v-btn>
        <v-btn
          depressed
          :disabled="formLoading"
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="action_dialog = false"
        >
          Cancel
        </v-btn>
      </template>
    </Dialog>

    <template v-if="validationErrorBag.length">
      <v-snackbar
        :timeout="5000"
        :value="true"
        top
        color="error"
        right
        v-for="(error, index) in validationErrorBag"
        :key="index"
      >
        {{ error }}
      </v-snackbar>
    </template>
  </v-app>
</template>

<script>
import Dialog from "@/view/pages/partials/Dialog.vue";
import SubscribeSubmit from "./Subscribe.vue";
import ValidationMixin from "@/core/plugins/validation-mixin";
// import { POST } from "@/core/services/store/request.module";
import CommonMixin from "@/core/plugins/common-mixin.js";

export default {
  name: "quotation-approve",
  mixins: [ValidationMixin, CommonMixin],
  data: () => {
    return {
      isConfirm: null,
      dialog: true,
      formLoading: false,
      isSubmitted: false,
      action_dialog: false,
      action_type: null,

      validationErrorBag: new Array(),

      comments: null,
      notesLoading: false,
    };
  },
  components: {
    Dialog,
    SubscribeSubmit,
  },
  methods: {
    action_submit() {
      this.isSubmitted = true;
      this.action_dialog = false;
    },
    onSubmit() {},
  },
};
</script>
<style type="text/css" scoped></style>
